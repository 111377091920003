import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
  morganFamily:{
    height: '35%',
    width: '35%'
  },
  about:{
      margin: '0 10%'
  }

});

interface PlayListState {
  currentMusicIndex: number
}

export default function FileSystemNavigator(this: any) {
  const classes = useStyles();
  const morganFamily = require('./images/morgan-family.jpg');
  return (
      <div className={classes.about}>
          <h1>About Dr. Morgan</h1>
          <img className={classes.morganFamily} src={morganFamily} alt="morgan-family"/>
          {/* <h2>Early life</h2> */}

          <h2>Seminary</h2>
          <p>
              When the Hildreths invited Darold Morgan and Elizabeth Johnson for a blind date, it meant
              the four of them would have to ride in a 1938 Chevrolet coupe to the Chicken Shack in Ft. Worth.
              It was a scenario of "close communion" and real "togetherness" (big words in Baptist life in the 40's).
              But this was hardly a date to celebrate doctrinal purity. Rather, it was a matter of keeping Seminary 
              dormitory rules. At that time, a young lady and a young man could not car date alone. They either had to double-date or take along a third
              party. Since the Hildreth's car was a one-seater, Elizabeth had to sit in Darold's lap. Supposedly,
              that was more moral than dating alone. Nevertheless, he has since maintained that she made quite an impression.
          </p>
          <p>
              It was not, however, love at first sight. Darold (age 21) was in his second year at Southwestern Seminary. Elizabeth (age 20) had just graduated from college. Neither was ready to 
              "settle down." So their contacts were casual and few. When the time came for Christmas vacation, he did offer to take her
              to the train in his 1936 Ford which he had just gotten from his brother. They recklessly ignored the double-data rule, rationalizing that
              this was a taxi service rather than a real date.
          </p>
          <p>
              But come spring, things began to warm up. He invited her to the Yankee Club banquet on Valentines Day
              and sent a red rose corsage. By this time, he had been called to two hal-time country churches. Also, he worked part-time in the Seminary
              dining hall at the beverage counter, and Elizabeth began to drink lots of coffee, in spite of the fact
              that it gave her terrible headaches. Both of them decided to go to summer school, and when they parted in August, they
              agreed to write--she from Illinois and he from Kansas.
          </p>
          <p>
             One month after returning in the fall, they were engaged to be married, with plans for a June wedding.
             Within a few months, Darold was accepted in the School of Theology for doctoral study, and Elizabeth applied for a teaching
             position with the Ft. Worth Public School District. They hired her as a pottery and ceramics specialist for all grades.
          </p>
          <p>
              After graduation in May  when both of them earned their Masters degrees, they were married on June 20th, 1947 in Carbondale, Illinois.
              Their wedding was the first wedding in a new chapel at Southern Illinois University, where Elizabeth's father taught.
          </p>
          <p>
              After a honeymoon in the Ozarks, they returned to Texas in the Ford, which left a blue cloud of smoke behind it all the way
              to Ft. Worth. It used so much oil that when they pulled into a service station, they asked the attendant to fill it with oil and see if they needed any gas. The right
              front window was broken out, which made for considerable dampness during a rainstorm. Futhermore, Elizabeth began to notice that her hosiery
              was disintegrating around her ankles after she rode in the car. It had to be leaking battery acid. By the grace of God, the Ford never left them stranded.
          </p>
          <h2>Early Ministry</h2>
          <p>
              Going to graduate school, pastoring country churches, and teaching school offered a rather full schedule. But youth is dauntless and refuses to consider
              how ill prepared both of them were for the life into which they were now thrust. Darold, one of five children, was the son of a railroader.
              Elizabeth and her older sister grew up in a preacher's home until she entered high school when her father was asked to teach at Southern Illinois University.
              Although she had a teaching degree, she soon discovered that college classes in education were a woefully inadequate preparation for the classroom.
          </p>
          <p>
              Within a year, Darold was called as a pastor of the First Baptist Church in Milford, Texas. Elizabeth got a teaching position in nearby Italy, Texas at a junior high school.
              Darold continued his graduate studies as well at the Seminary.
          </p>
          <p>
              In June 1949, Darold accepted a position as associate pastor at Highland Baptist Church in Dallas while Elizabeth taught at Obadiah Knight Elementary School in Dallas.
              For the next fifteen years, Darold and Elizabeth served in churches in Texas and Alabama (Bonham, Sulphur Springs, Sherman, Birmingham, and Dallas). They where
              in Birmingham at the height of the civil rights movement in the early 60's.
          </p>
          <p>
              Their family grew with two sons and a daughter who didn't seem to mind being preacher's kids. In fact, they liked it and thought it was a privilege.
          </p>
          <h2>Annuity Board</h2>
          <p>
              In 1966, the family returned to Texas from Alabama and lived in Oak Cliff in Dallas where Darold pastored Cliff Temple Baptist Church. After five years, he was 
              asked to become president of the Annuity Board of the Southern Baptist Convention where he served for nineteen years.
          </p>
          <p>
              The Morgans moved to Richardson in 1975 after Darold served as interim pastor at First Baptist Richardson. Darold retired from the Annuity board in 1989.
          </p>
          {/* <h2>Retirement and later years</h2> */}

    </div>
  );
}