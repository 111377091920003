import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
  morganFamily:{
    height: '35%',
    width: '35%'
  },
  about:{
      marginRight: '40%',
      marginLeft: '10%',
  }

});

interface PlayListState {
  currentMusicIndex: number
}

export default function FileSystemNavigator(this: any) {
  const classes = useStyles();
  return (
      <div className={classes.about}>
          <h1>Written Works</h1>
          <p>
              sdsdsd
          </p>
          
    </div>
  );
}