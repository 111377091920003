import React from 'react';
import './App.css';
import ButtonAppBar from './AppBar';
import HomePage from './HomePage'
import Tree from './Tree';
import Info from './About';
import WrittenWorks from './WrittenWorks'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function BasicExample() {
  return (
    <Router>
      <ButtonAppBar />
      <div>
        {/* <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/dashboard">Sermon Archives</Link>
          </li>
        </ul>

        <hr /> */}

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/sermon-archives">
            <Dashboard />
          </Route>
          <Route path="/written-works">
            <WrittenWorks />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

function Home() {
  return (
    <div>
      <HomePage />
    </div>
  );
}

function About() {
  return (
    <div>
      <Info />
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <Tree />
    </div>

  );
}
