import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menu: {
      display: 'flex',
      flexDirection: 'row',
      '@media (max-width: 450px)': {
        flexDirection: 'column',
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      textDecoration: "none",
      color: "#fff"
    },
  }),
);

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.menu}>
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" className={classes.title}>
            <Link className={classes.title} to="/">Home</Link>
          </Typography>
          <Typography variant="h6" className={classes.title}>
            <Link className={classes.title} to="/sermon-archives">Sermon Archives</Link>
          </Typography>
          <Typography variant="h6" className={classes.title}>
            <Link className={classes.title} to="/written-works">Written Works</Link>
          </Typography>
          <Typography variant="h6" className={classes.title}>
            <Link className={classes.title} to="/about">About</Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}
